const isServer = typeof window === 'undefined';

const DOWNLOAD_URL = 'https://github.com/dodlab/BGMs/releases/latest/download/';

const BASIC_CONSTANT = {
  BASE_URL: process.env.NEXT_PUBLIC_BACKEND_URL,
  BACKEND_GOOGLE_AUTH_URL: 'oauth2/authorize/google',
  USER_TOKEN_KEY: 'jj.user.token',
  DOWNLOAD_URL: DOWNLOAD_URL,
  NOTICE_POPUP: 'notice',
  REMOTE_WEB_URL: process.env.NEXT_PUBLIC_REMOTE_URL,
};

export default BASIC_CONSTANT;
